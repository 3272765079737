export default defineNuxtRouteMiddleware(async (to, from) => {
  const client = useSanctumClient()

  try {
    await client('/api/organizations/' + to.params.slug)
  }
  catch {
    return abortNavigation()
  }
})
